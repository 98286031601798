.i-container{
    justify-content: space-around;
}

.i-container>img{
    width: 5rem;
}

@media (max-width:1024px) {
    .i-container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-items: center;
    }
  }