.f-container{
    justify-content: space-around;
}
.f-left{
    gap: 1rem;
}
.iconss{
    margin-left: 3rem;
    padding-top: 0.5rem;
}
.f-menu{
    gap: 1.5rem;
    margin-top: 1.1rem;
    font-weight: 500;
}

@media (max-width: 1024px) {
    .f-container{
        justify-content: center;
    }
    .f-container>div{
        align-items: center !important;
        text-align: center;
    }
    .iconss{
        margin-left: 0;
    }
}