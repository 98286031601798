.App{
    position: relative;
    overflow-x: hidden;
}

.App>:nth-child(1)
{
    background: var(--black);
    position: relative;
}

