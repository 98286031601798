.r-container{
    overflow: hidden;
    position: relative;
}
.r-head{
    margin-bottom: 2rem;
    margin-left: 7rem;
}

.r-card{
    gap: 0;
    padding: 1rem;
    border-radius: 10px;
    max-width: max-content;
    margin: 5rem 5rem;
    margin-top: 12px;
    transition: all 300ms ease-in;
}
.r-card>img{
    width: 100%;
    max-width: 20rem;
}

.r-card>:nth-child(3)
{
    font-size: 1rem;
    font-weight: 600;
}
.r-card>:nth-child(4)
{
    font-size: 1rem;
}
.r-card>:nth-child(5) 
{
    font-size: 0.7rem;
    width: 15rem;
}

.r-card:hover{
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(
        180deg, 
        rgba(225, 225, 225, 0) 0%, 
        rgba(136, 160, 225, 0.46) 217.91%
    );
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 225, 0.21);
}

.swiper-horizontal{
    overflow: visible;
}
.r-buttons{
    margin-right: 7rem;
    position: absolute;
    gap: 1rem;
    top: -4rem;
    right: 0;
}
.r-buttons button{
    font-size: 1.2rem;
    padding: 0.2rem 0.8rem;
    color: blue;
    border: none;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}
.r-buttons>:nth-child(1) {
    background-color: #EEEEEF;
}
.r-buttons>:nth-child(2) {
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
}

@media (max-width: 640px) {
    .r-head{
        margin-left: 4rem;
    }
    .r-buttons{
        margin-right: 2rem;
    }

}