.inner-container{
    gap: 1.5rem;
    background: #4161df;
    padding: 2rem;
    border-radius: 10px;
    border: 6px solid #5d77d6;
    text-align: center;
    margin-left: 7rem;
    margin-right: 7rem;
}
.inner-container .primaryText{
    color: white;
    font-weight: 600;
}
.inner-container .secondaryText{
    color: rgba(255, 255, 255, 0.587);
}
.inner-container .button{
    background: #5a73d7;
    border: 2px solid white;
    box-shadow: var(--shadow);
    border-radius: 10px;
}

@media (max-width: 1024px ) {
    .g-container{
        width: 45rem;
        margin-left: -6rem;
    }
}